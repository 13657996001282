import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/:incomingInfo?',
        name: 'zoomClient',
        component: () => import( /* webpackChunkName: "zoom-client" */ '../views/ZoomClient.vue')
    },
    {
        path: '/leave',
        name: 'leave',
        component: () => import( /* webpackChunkName: "leave" */ '../views/Leave.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router